<template>
    <v-dialog v-model="show" max-width="1400" persistent>
      <v-card>
        <v-card-title class="headline">
            <v-icon class="mr-2" color="indigo">mdi-file-upload</v-icon>
            {{ $t('console.scheme.flow.importNanotasksDialog.title', { templateName: template }) }}
        </v-card-title>
        <v-card-text>
            <v-file-input
                show-size
                accept=".json,.JSON"
                :label="$t('console.scheme.flow.importNanotasksDialog.fileInputLabel')"
                @change="getFileContent"
            ></v-file-input>
        </v-card-text>

        <nanotask-table
            :nanotasks="contents"
            :search="search"
        ></nanotask-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog" >{{ $t('console.scheme.flow.importNanotasksDialog.buttons.cancel') }}</v-btn>
          <v-btn color="primary" @click="importNanotasks" :disabled="contents.length==0">{{ $t('console.scheme.flow.importNanotasksDialog.buttons.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import NanotaskTable from './DialogListNanotasks/NanotaskTable'
export default {
    data: () => ({
        show: false,
        headers: [],
        headerNames: [],
        contents: [],
        search: "",

        tagName: "",
        numAssignments: 1,
        priority: 1
    }),
    props: ['client', 'projectName', 'template'],
    components: {
        NanotaskTable
    },
    methods: {
        closeDialog() {
            this.contents = [];
            this.headers = [];
            this.show = false;
        },
        async importNanotasks() {
            await this.client.resource.createNanotasks({
                    project_name: this.projectName,
                    template_name: this.template,
                    nanotasks: this.contents,
                });
            this.closeDialog();
        },
        async getFileContent (file) {
            try {
                const content = await this.readFileAsync(file)
                switch(file.type){
                    case "application/json": {
                        this.contents = JSON.parse(content);
                    }
                }
            } catch (e) {
                console.log(e)
            }
        },
        async readFileAsync (file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
              resolve(reader.result);
            }
            reader.onerror = reject;
            reader.readAsText(file);
          })
        }
    }
}
</script>
