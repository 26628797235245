var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("console.scheme.flow.createNanotaskGroupDialog.title")
                ) +
                " "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t(
                    "console.scheme.flow.createNanotaskGroupDialog.textFieldLabel"
                  ),
                  step: "1",
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
          _c("tutti-dialog-actions", { attrs: { actions: _vm.actions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }