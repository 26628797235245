<template>
    <v-main class="grey lighten-4">
        <page-toolbar :title="$t('console.scheme.toolbar.title')">
            <template #contents>
                <v-btn text color="indigo" @click="openSchemeEditorWindow">
                    <v-icon left>mdi-file-code-outline</v-icon>
                    {{ $t('console.scheme.toolbar.buttons.openEditor') }}
                </v-btn>
                <v-btn text color="indigo" @click="loadFlow(false)">
                    <v-icon left>mdi-refresh</v-icon>
                    {{ $t('console.scheme.toolbar.buttons.refresh') }}
                </v-btn>
            </template>
        </page-toolbar>

        <v-row class="justify-center">
            <v-col cols="11" lg="5" class="pa-8 pr-4">
                <config-table :config="config" />
            </v-col>
            <v-col cols="11" lg="7" class="pa-8 pl-4">
                <task-flow-card
                    :client="client"
                    :project-name="projectName"
                    :template-names="templateNames"
                    :flow.sync="flow"
                    />
            </v-col>
        </v-row>

        <tutti-snackbar ref="snackbar" />

        <tutti-dialog-alert
            ref="dialogSchemeLoadError"
            max-width="800"
            :cancellable="false"
        >
            <template #title>
                <v-icon left color="error">mdi-alert</v-icon>
                {{ $t('console.scheme.loadFailureDialog.title') }}
            </template>
            <template #body>
                <v-card-text>
                    <textarea
                        style="width: 100%; height: 200px"
                        v-text="errorString"
                    ></textarea>
                </v-card-text>
            </template>
        </tutti-dialog-alert>

    </v-main>
</template>

<script>
import PageToolbar from '@/components/ui/PageToolbar'
import ConfigTable from './ConfigTable'
import TaskFlowCard from './TaskFlowCard'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'
import TuttiDialogAlert from '@/components/ui/TuttiDialogAlert'

export default {
    components: {
        PageToolbar,
        ConfigTable,
        TaskFlowCard,
        TuttiSnackbar,
        TuttiDialogAlert
    },
    data: () => ({
        flow: null,
        config: null,
        errorString: '',
        templateNames: []
    }),
    props: ["client", "projectName"],
    methods: {
        async init() {
            this.loadFlow();
            this.templateNames = await this.client.resource.listTemplates({ project_name: this.projectName });
        },
        async loadFlow(cached = true){
            if(this.projectName) {
                try {
                    const scheme = await this.client.resource.getProjectScheme({
                            cached,
                            project_name: this.projectName
                        });
                    this.flow = scheme.flow;
                    this.config = scheme.config;
                    this.$refs.snackbar.show("success", this.$t('console.scheme.snackbar.loadSuccess'));
                } catch (e) {
                    this.$refs.snackbar.show("error", this.$t('console.scheme.snackbar.loadError'));
                    this.errorString = e.details.stacktrace.join('');
                    this.$refs.dialogSchemeLoadError.show();
                }
            }
        },
        openSchemeEditorWindow() {
            window.open(`/code-editor/scheme?project_name=${this.projectName}`, 'schemeEditorWindow', ';');
        }
    },
    watch: {
        async projectName() {
            this.init();
        }
    },
    created() {
        this.client.invokeOnOpen(() => {
            this.init();
        });
    }
}
</script>
