<template>
    <v-card tile>
        <v-card-title>
            {{ $t('console.scheme.flow.title') }}
        </v-card-title>

        <v-divider></v-divider>

        <div class="flow-wrapper">
            <v-card
                flat
                align="center"
                class="mx-auto py-2 text-h6 white--text"
                color="grey darken-1"
                width="200"
            >
                {{ $t('console.scheme.flow.startNode') }}
            </v-card>

            <arrow depth="1"></arrow>

            <div v-if="flow" style="max-width:600px; margin: 0 auto;">
                <recursive-batch
                    :client="client"
                    :project-name="projectName"
                    :template-names="templateNames"
                    :node="flow"
                    :depth="1"
                    :isLast="true"
                    @parent-update="onParentUpdate"
                ></recursive-batch>
            </div>

            <arrow depth="1"></arrow>

            <v-card
                flat
                align="center"
                class="mx-auto py-2 text-h6 white--text"
                color="grey darken-1"
                width="200">
                {{ $t('console.scheme.flow.endNode') }}
            </v-card>
        </div>
    </v-card>
</template>

<script>
import RecursiveBatch from './RecursiveBatch'
import Arrow from './Arrow'

export default {
    components: {
        RecursiveBatch,
        Arrow,
    },
    props: ['client', 'projectName', 'flow', 'templateNames'],
    methods: {
        onParentUpdate(parentNode) {
            this.$emit('update:flow', parentNode);
        }
    }
}
</script>
<style scoped>
.flow-wrapper {
    background-image: url('~@/assets/flow-background.png');
    background-repeat: repeat;
    padding: 30px 0;
}
</style>