<template>
    <div
        @mouseover.stop="(e) => { hovered = true }"
        @mouseleave.stop="onMouseLeave"
        class="recursive-batch"
    >
        <div id="node-wrapper">
            <!-- node actions -->
            <div v-if="depth > 1" id="node-actions">
                <!-- <template v-if="hovered"> -->
                <template v-if="false">
                    <!-- up arrow -->
                    <v-tooltip left>
                        <template v-slot:activator="{ attrs, on }">
                            <v-icon
                                v-if="index !== 0"
                                id="node-action-icon-arrow-up"
                                class="node-action-icon"
                                v-bind="attrs"
                                v-on="on"
                                size="25"
                                @click="swapNodes(index, index-1)"
                            >mdi-arrow-up</v-icon>
                        </template>
                        <span>{{ $t('console.scheme.flow.tooltip.actions.moveUp') }}</span>
                    </v-tooltip>

                    <!-- node menu -->
                    <v-menu left bottom offset-x open-on-hover v-model="menuOpen">
                        <template v-slot:activator="{ attrs, on }">
                            <v-icon
                                size="25"
                                v-bind="attrs"
                                v-on="on"
                                id="node-action-icon-dots"
                                class="node-action-icon"
                            >mdi-dots-vertical</v-icon>
                        </template>

                        <v-list dense class="py-0">
                            <v-list-item
                                @click="addTemplateNode(index)"
                            >
                                <v-list-item-title>
                                    <v-icon small left>mdi-plus</v-icon>
                                    {{ $t('console.scheme.flow.node.actions.menu.addTemplate') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                @click="groupNode(index)"
                            >
                                <v-list-item-title>
                                    <v-icon small left>mdi-group</v-icon>
                                    {{ $t('console.scheme.flow.node.actions.menu.group') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="isBatch"
                                @click="ungroupNode(index)"
                            >
                                <v-list-item-title>
                                    <v-icon small left>mdi-ungroup</v-icon>
                                    {{ $t('console.scheme.flow.node.actions.menu.ungroup') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="index > 0 && parentNode.children[index-1].children"
                                @click="moveNodeIntoGroup('previous', index)"
                            >
                                <v-list-item-title>
                                    <v-icon small left>mdi-arrow-right-top</v-icon>
                                    {{ $t('console.scheme.flow.node.actions.menu.moveIntoPreviousGroup') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="!isLast && parentNode.children[index+1].children"
                                @click="moveNodeIntoGroup('next', index)"
                            >
                                <v-list-item-title>
                                    <v-icon small left>mdi-arrow-right-bottom</v-icon>
                                    {{ $t('console.scheme.flow.node.actions.menu.moveIntoNextGroup') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="node.children && node.children.length > 1"
                                @click="popChildOutOfGroup('first')"
                            >
                                <v-list-item-title>
                                    <v-icon small left>mdi-arrow-left-top</v-icon>
                                    {{ $t('console.scheme.flow.node.actions.menu.moveFirstChildOutOfGroup') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="node.children && node.children.length > 1"
                                @click="popChildOutOfGroup('last')"
                            >
                                <v-list-item-title>
                                    <v-icon small left>mdi-arrow-left-bottom</v-icon>
                                    {{ $t('console.scheme.flow.node.actions.menu.moveLastChildOutOfGroup') }}
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                                v-if="parentNode.children.length > 1"
                                @click="deleteNode(index)"
                            >
                                <v-list-item-title>
                                    <v-icon small left color="error">mdi-delete</v-icon>
                                    <span class="error--text">{{ $t('console.scheme.flow.node.actions.menu.delete') }}</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <!-- down arrow -->
                    <v-tooltip left>
                        <template v-slot:activator="{ attrs, on }">
                            <v-icon
                                v-if="!isLast"
                                size="25"
                                id="node-action-icon-arrow-down"
                                class="node-action-icon"
                                v-bind="attrs"
                                v-on="on"
                                @click="swapNodes(index, index+1)"
                            >mdi-arrow-down</v-icon>
                        </template>
                        <span>{{ $t('console.scheme.flow.tooltip.actions.moveDown') }}</span>
                    </v-tooltip>
                </template>
            </div>

            <!-- card -->
            <v-card
                :flat="isBatch"
                :color="cardColor"
                class="mx-auto"
                :style="{ width: '100%', ...(isBatch ? { border: '1px solid #ccc!important' } : {}) }"
            >

                <!-- header -->
                <div :class="`card-toolbar d-flex lighten-1 ${isBatch ? '' : 'blue-'}grey`">
                    <div class="ma-0 mr-2" justify="center">
                        <div v-if="isBatch" class="grey node-type-chip">
                            <v-icon small left color="white">mdi-group</v-icon>
                            {{ $t('console.scheme.flow.batch') }}
                        </div>
                        <div v-else class="blue-grey node-type-chip">
                            <v-icon small left color="white">mdi-application-outline</v-icon>
                            {{ $t('console.scheme.flow.template') }}
                        </div>
                    </div>

                    <div class="node-name" :style="input.style">
                        <v-text-field
                            v-model="node.name"
                            readonly
                            hide-details
                            class="pt-1"
                        ></v-text-field>
                        <!-- <validated-text-field
                            v-if="isBatch"
                            v-model="node.name"
                            :rules="input.rules"
                            @update:error="(e) => { input.style.backgroundColor = e ? 'rgb(220, 200, 200)' : 'rgba(255,255,255,0.3)' }"
                        ></validated-text-field>
                        <validated-select
                            v-if="!isBatch"
                            v-model="node.name"
                            :items="templateNames"
                            :rules="[(v) => (v !== '')]"
                            @update:error="(e) => { input.style.backgroundColor = e ? 'rgb(220, 200, 200)' : 'rgba(255,255,255,0.3)' }"
                        ></validated-select> -->
                    </div>
                </div>

                <!-- chips -->
                <div class="card-toolbar">
                    <!-- if or loop chip -->
                    <v-chip
                        v-if="node.statement"
                        small
                        label
                        class="white--text mr-2"
                        :color="statements[node.statement].color"
                        @click="showCondition = !showCondition"
                    >
                        <v-icon left color="white">{{ statements[node.statement].icon }}</v-icon>
                        {{ $t(statements[node.statement].i18nKey) }}
                        <v-icon
                            small
                            right
                            color="white"
                        >mdi-unfold-{{ showCondition ? 'less' : 'more' }}-horizontal</v-icon>
                    </v-chip>

                    <!-- no condition chip -->
                    <v-chip
                        v-if="!node.statement"
                        class="mr-2"
                        small
                        label
                    >
                        <v-icon small left>mdi-pencil-off</v-icon>
                        {{ $t('console.scheme.flow.card.noCondition') }}
                    </v-chip>

                    <!--<chip-select
                        v-if="!node.statement"
                        small
                        label
                        prepend-icon="mdi-plus"
                        :placeholder="$t('console.scheme.flow.card.setCondition')"
                        :items="[client.ENUM.Statement.IF, client.ENUM.Statement.WHILE].map(type => ({
                            text: $t(statements[type].i18nKey),
                            onClick: () => { createCondition(type) },
                            icon: statements[type].icon,
                        }))"
                    ></chip-select>-->

                    <!-- skippability chip -->
                    <!-- <chip-select
                        small
                        label
                        outlined
                        :prepend-icon="node.is_skippable ? 'mdi-debug-step-over' : 'mdi-cancel'"
                        :items="[
                            { text: $t('console.scheme.flow.card.skippable'), onClick: () => { changeSkippability(true) }, icon: 'mdi-debug-step-over' },
                            { text: $t('console.scheme.flow.card.not_skippable'), onClick: () => { changeSkippability(false) }, icon: 'mdi-cancel', iconColor: 'error' },
                        ]"
                    >
                        <template v-slot:chip="{ attrs, on }">
                            <v-chip v-bind="attrs" v-on="on" small label outlined class="mr-2"> -->
                            <v-chip small label outlined class="mr-2">
                                <v-icon v-if="node.is_skippable" left small>mdi-debug-step-over</v-icon>
                                <v-icon v-else left small color="error">mdi-cancel</v-icon>
                                {{ $t(`console.scheme.flow.card.${node.is_skippable ? '' : 'not_'}skippable`) }}
                                <!-- <v-icon right small>mdi-menu-down</v-icon> -->
                            </v-chip>
                        <!-- </template>
                    </chip-select> -->

                    <!-- nanotask chip -->
                    <chip-select
                        v-if="!isBatch"
                        small
                        label
                        outlined
                        :items="[
                            { text: $t('console.scheme.flow.menu.listNanotasks'),   onClick: () => { $refs.dlgList.show = true; },   icon: 'mdi-view-list-outline' },
                            { text: $t('console.scheme.flow.menu.importNanotasks'), onClick: () => { $refs.dlgImport.show = true; }, icon: 'mdi-upload' },
                            { text: $t('console.scheme.flow.menu.createNanotasks'), onClick: () => { $refs.dlgCreate.shown = true; }, icon: 'mdi-pencil' }
                        ]"
                        placeholder="適用範囲"
                    >
                        <template v-slot:chip="{ attrs, on }">
                            <v-chip
                                v-bind="attrs"
                                v-on="on"
                                small
                                label
                                :outlined="nanotasks.length === 0"
                                class="mr-2"
                                :color="'indigo'+(nanotasks.length > 0 ? ' lighten-4' : '')"
                                text-color="indigo"
                            >
                                <v-icon small left>mdi-tray{{ nanotasks.length > 0 ? '-full' : '' }}</v-icon>
                                {{ $t('console.scheme.flow.nanotasks') }} ({{ nanotasks.length }})
                                <v-icon right small>mdi-menu-down</v-icon>
                            </v-chip>
                        </template>
                    </chip-select>
                </div>

                <!-- condition -->
                <template v-if="showCondition">
                    <!-- <v-divider></v-divider>
                    <v-btn-toggle v-model="node.conditionMode" mandatory dense style="width: 100%;">
                        <v-btn style="width: 50%; height: 30px;" value="ui">
                            <v-checkbox :value="node.conditionMode === 'ui'"></v-checkbox>
                            UI
                        </v-btn>
                        <v-btn style="width: 50%; height: 30px;" value="code">
                            <v-checkbox :value="node.conditionMode === 'code'"></v-checkbox>
                            Code
                        </v-btn>
                    </v-btn-toggle> -->
                    <div
                        v-for="condition, i in node.conditionNew"
                        :key="`${node.name}-condition-${i}`"
                    >
                        <div class="d-flex">
                            <div style="width: 100px; display: flex; justify-content: center; align-items: center; text-align: center;">
                            </div>
                            <div>
                                <v-row
                                    class="pa-2"
                                >
                                    <v-col cols="12">
                                        <v-select
                                            v-model="condition.scope"
                                            outlined
                                            dense
                                            hide-details
                                            :items="[
                                                { text: 'ワーカー', value: 'worker' },
                                                { text: 'フロー', value: 'session' }
                                            ]"
                                            style="font-size: 12px; width: 35%;"
                                        >
                                            <template v-slot:label>
                                                <span style="font-size:12px;">適用範囲</span>
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-select
                                            v-model="condition.variable"
                                            outlined
                                            dense
                                            hide-details
                                            :items="[
                                                { text: '過去訪問回数', value: 'count' },
                                                { text: '滞在時間', value: 'time' },
                                                { text: '正答率', value: 'accuracy' }
                                            ]"
                                            style="font-size:12px;"
                                        >
                                            <template v-slot:label>
                                                <span style="font-size:12px;">変数</span>
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="3">
                                        <v-select
                                            v-model="condition.operand"
                                            outlined
                                            dense
                                            hide-details
                                            :items="[
                                                { text: '&lt;', value: 'lt' },
                                                { text: '&le;', value: 'le' },
                                                { text: '&gt;', value: 'gt' },
                                                { text: '&ge;', value: 'ge' },
                                                { text: '=', value: 'eq' },
                                                { text: '&ne;', value: 'neq' },
                                            ]"
                                        >
                                            <template v-slot:label>
                                                <span style="font-size:12px;">演算子</span>
                                            </template>
                                            <template v-slot:selection="{ item }">
                                                <span v-html="item.text"></span>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <span v-html="item.text"></span>
                                            </template>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="3">
                                    <v-text-field
                                        v-model="condition.value"
                                        outlined
                                        dense
                                        hide-details
                                        :type="valueTypeMap[condition.variable]"
                                        :suffix="valueSuffixMap[condition.variable]"
                                        style="font-size:12px;"
                                    >
                                        <template v-slot:label>
                                            <span style="font-size:12px;">値</span>
                                        </template>
                                    </v-text-field>

                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                    <codemirror
                        v-if="node.condition"
                        v-model="node.condition"
                        :options="cmOptions"
                        style="font-size: 12px; height: 100px!important;"
                    ></codemirror>
                </template>

                <!-- batch children -->
                <template v-if="isBatch">
                    <v-divider></v-divider>
                    <div style="margin: 0 10px 0 auto; padding: 25px 0;">
                        <recursive-batch
                            v-for="(child, index) in children"
                            :key="`recursive-batch-${node}-${index}`"
                            :client="client"
                            :index="index"
                            :project-name="projectName"
                            :template-names="templateNames"
                            :parent-node="node"
                            :node="child"
                            :isLast="index==children.length-1"
                            :depth="depth+1"
                            @parent-update="relayParentUpdate"
                        ></recursive-batch>
                    </div>
                </template>

            </v-card>
        </div>

        <arrow v-if="!isLast" :depth="depth"></arrow>

        <dialog-import :client="client" :projectName="projectName" :template="node.name" ref="dlgImport" />
        <dialog-create :client="client" :projectName="projectName" :template="node.name" ref="dlgCreate" />
        <dialog-list :client="client" :projectName="projectName" :template="node.name" :nanotasks="nanotasks" ref="dlgList" />

    </div>
</template>

<script>
import RecursiveBatch from './RecursiveBatch'
import Arrow from './Arrow'
import DialogImport from './DialogImportNanotasks'
import DialogCreate from './DialogCreateNanotasks'
import DialogList from './DialogListNanotasks'
//import ValidatedTextField from '@/components/pages/Console/Platforms/MTurk/HIT/Create/ui/ValidatedTextField.vue'
//import ValidatedSelect from '@/components/pages/Console/Platforms/MTurk/HIT/Create/ui/ValidatedSelect.vue'
import ChipSelect from './ChipSelect.vue'

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/python/python'
import 'codemirror/theme/base16-light.css'

export default {
    name: "RecursiveBatch",
    components: {
        RecursiveBatch,
        Arrow,
        DialogImport,
        DialogCreate,
        DialogList,
        //ValidatedTextField,
        //ValidatedSelect,
        codemirror,
        ChipSelect
    },
    data() {
        return {
            hovered: false,
            menuOpen: false,
            showCondition: false,
            color: "grey",
            cmOptions: {
                tabSize: 4,
                mode: 'text/x-python',
                lineNumbers: true,
                line: true,
                readOnly: 'nocursor'
            },
            nanotasks: [],
            input: {
                rules: [(v) => (v !== '' || '')],
                style: {
                    width: '-webkit-fill-available',
                    borderRadius: '3px',
                    backgroundColor: 'rgba(255,255,255,0.4)',
                }
            },
            valueSuffixMap: {
                count: '回',
                time: '秒',
                accuracy: '%'
            },
            valueTypeMap: {
                count: 'number',
                time: 'number',
                accuracy: 'number'
            }
        }
    },
    props: ['client', 'index', 'projectName', 'templateNames', 'parentNode', 'node', 'isLast', 'depth'],
    computed: {
        cardColor() { return this.isBatch ? 'white' : 'blue-grey lighten-5'; },
        children() { return this.node ? this.node.children : []; },
        isBatch() { return this.children ? this.children.length>0 : false; },
        hasNanotask() { return !this.isBatch && this.nanotasks.length>0; },
    },
    methods: {
        dataIsForMyComponent(data) {
            return data.project_name==this.projectName && data.template_name==this.node.name;
        },
        async listNanotasks() {
            const data = await this.client.resource.listNanotasks({
                    project_name: this.projectName,
                    template_name: this.node.name
                });
            if(this.dataIsForMyComponent(data))  this.nanotasks = data.nanotasks;
        },

        swapNodes(indexFrom, indexTo) {
            let parentNode = { ...this.parentNode };
            const nodeFrom = parentNode.children[indexFrom];
            const nodeTo = parentNode.children[indexTo];
            parentNode.children[indexFrom] = nodeTo;
            parentNode.children[indexTo] = nodeFrom;
            this.$emit('parent-update', parentNode);
        },
        groupNode(index) {
            const node = {
                name: '',
                children: [{ ...this.node }]
            };
            let parentNode = { ...this.parentNode };
            parentNode.children[index] = node;
            this.$emit('parent-update', parentNode);
        },
        ungroupNode(index) {
            let parentNode = { ...this.parentNode };
            parentNode.children.splice(index, 1, ...this.node.children)
            this.$emit('parent-update', parentNode);
        },
        moveNodeIntoGroup(direction, index) {
            let parentNode = { ...this.parentNode };
            if(direction === 'previous') {
                parentNode.children[index-1].children.push({ ...parentNode.children[index] });
            } else if(direction === 'next') {
                parentNode.children[index+1].children.unshift({ ...parentNode.children[index] });
            }
            parentNode.children.splice(index, 1);
            this.$emit('parent-update', parentNode);
        },
        popChildOutOfGroup(position) {
            let parentNode = { ...this.parentNode };
            let node = { ...this.node };
            if(position === 'first') {
                const firstNode = node.children.shift();
                parentNode.children.splice(this.index, 1, firstNode, node);
            } else if(position === 'last') {
                const lastNode = node.children.pop();
                parentNode.children.splice(this.index, 1, node, lastNode);
            }
            this.$emit('parent-update', parentNode);
        },
        addTemplateNode(index) {
            let parentNode = { ...this.parentNode };
            parentNode.children.splice(index+1, 0, {
                name: '',
                is_skippable: false,
            })
            this.$emit('parent-update', parentNode);
        },
        deleteNode(index) {
            let parentNode = { ...this.parentNode };
            parentNode.children.splice(index, 1);
            this.$emit('parent-update', parentNode);
        },
        relayParentUpdate(child) {
            if(this.depth > 1) {
                let parentNode = { ...this.parentNode };
                parentNode.children[this.index] = child;
                this.$emit('parent-update', parentNode);
            } else {
                this.$emit('parent-update', child);
            }
        },
        onMouseLeave() {
            if(!this.menuOpen) this.hovered = false;
        },

        changeSkippability(isSkippable) {
            let parentNode = { ...this.parentNode };
            let node = { ...this.node };
            node.is_skippable = isSkippable;
            parentNode.children[this.index] = node;
            this.$emit('parent-update', parentNode);
        },

        createCondition(statement) {
            this.node.statement = statement;
            this.node.conditionNew = [{}];
            this.showCondition = true;
        },
    },
    watch: {
        menuOpen() {
            if(!this.menuOpen) this.hovered = false;
        }
    },
    created() {
        this.client.invokeOnOpen(() => {
            this.client.resource.on(["createNanotasks", "deleteNanotasks"], {
                success: () => {
                    this.listNanotasks();
                },
            });

            this.statements = {};
            this.statements[this.client.ENUM.Statement.IF] = {
                color: 'blue',
                icon: 'mdi-help-rhombus-outline',
                i18nKey: 'console.scheme.flow.card.if'
            };
            this.statements[this.client.ENUM.Statement.WHILE] = {
                color: 'green',
                icon: 'mdi-repeat-variant',
                i18nKey: 'console.scheme.flow.card.while'
            };

            if(!this.isBatch){ this.listNanotasks(); }
        });
    }
}
</script>
<style>
.CodeMirror {
    font-family: Monaco, 'Andale Mono', 'Lucida Console', 'Bitstream Vera Sans Mono', 'Courier New';
    height: 100px!important;
}
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    z-index: 3;
}
.node-name input {
    margin: 0;
    padding: 0 10px;
}
</style>
<style scoped>
#node-wrapper {
    position: relative;
    display: flex;
}
#node-actions {
    width: 45px;
    height: 100%;
    padding: 0 20px;
}
.node-type-chip {
    color: white;
    padding: 0 8px !important;
    font-weight: bold;
    font-size: 0.75rem !important;
    border-radius: 3px;
    width: max-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.node-name > div {
    margin-top: 0;
}
.node-action-icon {
    position: absolute;
    left: 10px;
}
#node-action-icon-arrow-up   { top: calc(100% / 2 - 12.5px - 30px); }
#node-action-icon-dots       { top: calc(100% / 2 - 12.5px); cursor: pointer; }
#node-action-icon-arrow-down { top: calc(100% / 2 - 12.5px + 30px); }
.card-toolbar {
    padding: 3px 8px;
    font-size: 14px;
    font-weight: bold;
}
</style>