<template>
    <v-data-table
        dense
        show-select
        v-model="selectedNanotasks"
        :loading="loading"
        :headers="headers"
        :items="nanotasks"
        item-key="id"
        :search="search"
        :footer-props="footerProps"
        sort-by="NanotaskId"
        >
        <template v-slot:item.reference_answers="{ item }">
            <v-simple-table dense>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="(value, key) in item.reference_answers" :key="key">
                            <td style="width:100px"><b>{{ key }}</b></td>
                            <td style="word-break:break-all">{{ value }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
        <template v-slot:item.props="{ item }">
            <v-simple-table dense>
                <template v-slot:default>
                    <tbody>
                        <tr v-for="(value, key) in item.props" :key="key">
                            <td style="width:100px"><b>{{ key }}</b></td>
                            <td style="word-break:break-all">{{ value }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
        <template v-slot:item._created_at="{ item }">
            {{ standardDateTimeFormat(item._created_at) }}
        </template>
    </v-data-table>
</template>
<script>
import { standardDateTimeFormat } from '@/lib/utils'

export default {
    data() {
        return {
            loading: false,
            headers: [
                    { text: this.$t('resources.nanotask.id'),               value: 'id',                width: '10%' },
                    { text: this.$t('resources.nanotask.tag'),              value: 'tag',               width: '10%' },
                    { text: this.$t('resources.nanotask.numAssignable'),    value: 'num_assignable',    width: '1%'  },
                    { text: this.$t('resources.nanotask.priority'),         value: 'priority',          width: '1%'  },
                    { text: this.$t('resources.nanotask.referenceAnswers'), value: 'reference_answers', width: '20%' },
                    { text: this.$t('resources.nanotask.props'),            value: 'props',             width: '40%' },
                    { text: this.$t('resources.nanotask.createdAt'),        value: '_created_at',       width: '15%' }
                ],
            footerProps: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-chevron-double-left',
                    lastIcon: 'mdi-chevron-double-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    itemsPerPageOptions: [10,30,50,100,-1]
                },
            selectedNanotasks: []
        }
    },
    props: ['search', 'nanotasks'],
    methods: {
        standardDateTimeFormat,
    },
    watch: {
        nanotasks() {
            this.loading = false;
        },
        selectedNanotasks(val) {
            this.$emit('select', val);
        }
    }
}
</script>
