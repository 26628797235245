var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "350" },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("console.scheme.flow.deleteNanotasksDialog.title")
                ) +
                " "
            ),
          ]),
          _c("v-card-text", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("console.scheme.flow.deleteNanotasksDialog.text")
                ) +
                " "
            ),
          ]),
          _c("tutti-dialog-actions", { attrs: { actions: _vm.actions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }