var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c("page-toolbar", {
        attrs: { title: _vm.$t("console.scheme.toolbar.title") },
        scopedSlots: _vm._u([
          {
            key: "contents",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "indigo" },
                    on: { click: _vm.openSchemeEditorWindow },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-file-code-outline"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("console.scheme.toolbar.buttons.openEditor")
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "indigo" },
                    on: {
                      click: function ($event) {
                        return _vm.loadFlow(false)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-refresh"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("console.scheme.toolbar.buttons.refresh")
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-col",
            { staticClass: "pa-8 pr-4", attrs: { cols: "11", lg: "5" } },
            [_c("config-table", { attrs: { config: _vm.config } })],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-8 pl-4", attrs: { cols: "11", lg: "7" } },
            [
              _c("task-flow-card", {
                attrs: {
                  client: _vm.client,
                  "project-name": _vm.projectName,
                  "template-names": _vm.templateNames,
                  flow: _vm.flow,
                },
                on: {
                  "update:flow": function ($event) {
                    _vm.flow = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("tutti-snackbar", { ref: "snackbar" }),
      _c("tutti-dialog-alert", {
        ref: "dialogSchemeLoadError",
        attrs: { "max-width": "800", cancellable: false },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("v-icon", { attrs: { left: "", color: "error" } }, [
                  _vm._v("mdi-alert"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("console.scheme.loadFailureDialog.title")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _c("textarea", {
                    staticStyle: { width: "100%", height: "200px" },
                    domProps: { textContent: _vm._s(_vm.errorString) },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }