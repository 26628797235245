<template>
    <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
            <slot name="chip" v-if="$scopedSlots.chip" v-bind="{ attrs, on }"></slot>
            <v-chip
                v-if="!$scopedSlots.chip"
                v-bind="{ ...attrs, outlined, small, label, dark }"
                v-on="on"
                class="mr-2"
            >
                <v-icon v-if="prependIcon" :small="prependIconSize!=='normal'" left :color="prependIconColor || value ? value.iconColor : ''">{{ prependIcon }}</v-icon>
                <b>{{ value ? value.text : placeholder }}</b>
                <v-icon small right>mdi-menu-down</v-icon>
            </v-chip>
        </template>
        <v-list dense class="py-0">
            <v-list-item-group>
                <v-list-item
                    v-for="item, i in items"
                    :key="`chip-select-${i}`"
                    @click="onSelect(item)"
                >
                    <v-list-item-title>
                        <v-icon v-if="item.icon" small left :color="item.iconColor">{{ item.icon }}</v-icon>
                        {{ item.text }}
                    </v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    props: [
        'value',
        'placeholder',
        'items',
        'outlined',
        'small',
        'label',
        'dark',
        'prependIcon',
        'prependIconColor',
        'prependIconSize',
    ],
    methods: {
        onSelect(item) {
            this.$emit('input', item);
            if(item.onClick) item.onClick();
        }
    },
}
</script>