var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ attrs, on }) {
              return [
                _vm.$scopedSlots.chip
                  ? _vm._t("chip", null, null, { attrs, on })
                  : _vm._e(),
                !_vm.$scopedSlots.chip
                  ? _c(
                      "v-chip",
                      _vm._g(
                        _vm._b(
                          { staticClass: "mr-2" },
                          "v-chip",
                          {
                            ...attrs,
                            outlined: _vm.outlined,
                            small: _vm.small,
                            label: _vm.label,
                            dark: _vm.dark,
                          },
                          false
                        ),
                        on
                      ),
                      [
                        _vm.prependIcon
                          ? _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: _vm.prependIconSize !== "normal",
                                  left: "",
                                  color:
                                    _vm.prependIconColor || _vm.value
                                      ? _vm.value.iconColor
                                      : "",
                                },
                              },
                              [_vm._v(_vm._s(_vm.prependIcon))]
                            )
                          : _vm._e(),
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.value ? _vm.value.text : _vm.placeholder)
                          ),
                        ]),
                        _c("v-icon", { attrs: { small: "", right: "" } }, [
                          _vm._v("mdi-menu-down"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _c(
        "v-list",
        { staticClass: "py-0", attrs: { dense: "" } },
        [
          _c(
            "v-list-item-group",
            _vm._l(_vm.items, function (item, i) {
              return _c(
                "v-list-item",
                {
                  key: `chip-select-${i}`,
                  on: {
                    click: function ($event) {
                      return _vm.onSelect(item)
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-title",
                    [
                      item.icon
                        ? _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                left: "",
                                color: item.iconColor,
                              },
                            },
                            [_vm._v(_vm._s(item.icon))]
                          )
                        : _vm._e(),
                      _vm._v(" " + _vm._s(item.text) + " "),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }