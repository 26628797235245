var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    { staticClass: "text-center mx-auto", attrs: { color: "rgba(0,0,0,0)" } },
    [_c("v-icon", { attrs: { size: "70" } }, [_vm._v("mdi-arrow-down-thin")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }