var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      dense: "",
      "sort-desc": "",
      loading: _vm.loading,
      headers: _vm.headers,
      items: _vm.nanotaskGroups,
      search: _vm.search,
      "footer-props": _vm.footerProps,
      "sort-by": "_created_at",
    },
    scopedSlots: _vm._u([
      {
        key: "item._created_at",
        fn: function ({ item }) {
          return [
            _vm._v(
              " " + _vm._s(_vm.standardDateTimeFormat(item._created_at)) + " "
            ),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function ({ item }) {
          return [
            _c(
              "v-icon",
              {
                attrs: { small: "" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("delete", item.id, item.name)
                  },
                },
              },
              [_vm._v("mdi-trash-can")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }