var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v(" " + _vm._s(_vm.$t("console.scheme.flow.title")) + " "),
      ]),
      _c("v-divider"),
      _c(
        "div",
        { staticClass: "flow-wrapper" },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto py-2 text-h6 white--text",
              attrs: {
                flat: "",
                align: "center",
                color: "grey darken-1",
                width: "200",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("console.scheme.flow.startNode")) + " "
              ),
            ]
          ),
          _c("arrow", { attrs: { depth: "1" } }),
          _vm.flow
            ? _c(
                "div",
                { staticStyle: { "max-width": "600px", margin: "0 auto" } },
                [
                  _c("recursive-batch", {
                    attrs: {
                      client: _vm.client,
                      "project-name": _vm.projectName,
                      "template-names": _vm.templateNames,
                      node: _vm.flow,
                      depth: 1,
                      isLast: true,
                    },
                    on: { "parent-update": _vm.onParentUpdate },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("arrow", { attrs: { depth: "1" } }),
          _c(
            "v-card",
            {
              staticClass: "mx-auto py-2 text-h6 white--text",
              attrs: {
                flat: "",
                align: "center",
                color: "grey darken-1",
                width: "200",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("console.scheme.flow.endNode")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }