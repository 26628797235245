<template>
    <v-dialog v-model="shown" max-width="1000" persistent>
        <v-card>
            <v-card-title class="headline">
                <v-icon class="mr-2" color="indigo">mdi-file-upload</v-icon>
                {{ $t('console.scheme.flow.createNanotasksDialog.title', { templateName: template }) }}
            </v-card-title>
            <v-card-text>
                <p class="text-h6 mt-2">{{ $t('console.scheme.flow.createNanotasksDialog.commonSettings.title') }}</p>
                <v-divider />
                <v-simple-table dense>
                    <tbody>
                        <tr v-for="key,i in Object.keys(data.settings)" :key="`settings-${i}`">
                            <th>{{ paramNames[key] }}</th>
                            <td>
                                <v-text-field
                                    v-if="['num_assignable', 'priority'].includes(key)"
                                    dense
                                    hide-details
                                    type="number"
                                    v-model.number="data.settings[key]"
                                    />
                                <v-text-field
                                    v-else
                                    dense
                                    hide-details
                                    v-model="data.settings[key]"
                                    />
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-divider />
                <p class="text-h6 mt-5">{{ $t('console.scheme.flow.createNanotasksDialog.nanotasks.title') }}</p>
                <v-combobox 
                    small-chips
                    dense
                    multiple
                    v-model="props"
                    style="width:50%;"
                    :label="$t('console.scheme.flow.createNanotasksDialog.nanotasks.propsCombobox')"
                    />
                <v-combobox 
                    small-chips
                    dense
                    multiple
                    v-model="refs"
                    style="width:50%;"
                    :label="$t('console.scheme.flow.createNanotasksDialog.nanotasks.refAnsCombobox')"
                    />
                <v-divider />
                <v-simple-table dense>
                    <tbody v-for="nt,i in data.nanotasks" :key="`nanotask-${i}`"> 
                            <tr>
                                <th>
                                    {{ $t('console.scheme.flow.createNanotasksDialog.nanotasks.name') }} {{ i+1 }}
                                    <v-icon @click="copyNanotask(i)">mdi-content-copy</v-icon>
                                    <v-icon @click="deleteNanotask(i)">mdi-trash-can-outline</v-icon>
                                    <v-icon v-if="i!=0" @click="moveNanotask(i, -1)">mdi-arrow-up</v-icon>
                                    <v-icon v-if="i!=data.nanotasks.length-1" @click="moveNanotask(i, 1)">mdi-arrow-down</v-icon>
                                </th>
                                <td>
                                    <v-simple-table dense>
                                        <tbody>
                                            <tr v-for="key in Object.keys(nt)" :key="`nanotask-${i}-${key}`">
                                                <th>{{ paramNames[key] }}</th>
                                                <td>
                                                    <v-text-field
                                                        v-if="key=='id'"
                                                        dense
                                                        hide-details
                                                        v-model="data.nanotasks[i][key]"
                                                        />
                                                    <v-text-field
                                                        v-if="['num_assignable', 'priority'].includes(key)"
                                                        dense
                                                        hide-details
                                                        type="number"
                                                        v-model.number="data.nanotasks[i][key]"
                                                        />
                                                    <v-simple-table dense v-if="['props', 'reference_answers'].includes(key)">
                                                        <tbody>
                                                            <tr v-for="key_ in Object.keys(nt[key])" :key="`nanotask-${i}-${key_}`">
                                                                <th>{{ key_ }}</th>
                                                                <td>
                                                                    <v-text-field
                                                                        dense
                                                                        hide-details
                                                                        v-model="data.nanotasks[i][key][key_]"
                                                                        />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </v-simple-table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </td>
                            </tr>
                    </tbody>
                </v-simple-table>
                <v-divider />
                <v-icon @click="insertNanotask()">mdi-plus</v-icon>
                <v-divider />
            </v-card-text>
            <tutti-dialog-actions :actions="actions" />
            <tutti-snackbar ref="snackbar" />
        </v-card>
    </v-dialog>
</template>

<script>
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'

export default {
    components: {
        TuttiDialogActions,
        TuttiSnackbar
    },
    data() {
        return {
            shown: false,
            headers: [],
            headerNames: [],
            contents: [],
            search: "",
            paramNames: {
                id:                this.$t('resources.nanotask.id'),              
                tag:               this.$t('resources.nanotask.tag'),             
                num_assignable:    this.$t('resources.nanotask.numAssignable'),   
                priority:          this.$t('resources.nanotask.priority'),        
                reference_answers: this.$t('resources.nanotask.referenceAnswers'),
                props:             this.$t('resources.nanotask.props'),           
                _created_at:       this.$t('resources.nanotask.createdAt'),       
            },

            tag: "",
            numAssignments: 1,
            priority: 1,

            data: {
                settings: {
                    num_assignable: 1,
                    priority: 1,
                    tag: '',
                },
                nanotasks: []
            },
            props: [],
            refs: [],
            actions: [
                {
                    label: this.$t('console.scheme.flow.createNanotasksDialog.buttons.cancel'),
                    callback: () => { this.shown = false; }
                },
                {
                    label: this.$t('console.scheme.flow.createNanotasksDialog.buttons.confirm'),
                    color: 'indigo darken-2',
                    callback: async () => {
                        try {
                            await this.client.resource.createNanotasks({
                                project_name: this.projectName,
                                template_name: this.template,
                                nanotasks: this.data.nanotasks.map((nt) => ({
                                        id: nt.id,
                                        props: nt.props,
                                        reference_answers: nt.reference_answers,
                                        priority: nt.priority || this.data.settings.priority,
                                        num_assignable: nt.num_assignable || this.data.settings.num_assignable,
                                        tag: nt.tag || this.data.settings.tag,
                                    }))
                            });
                            this.$refs.snackbar.show('success', this.$t('console.scheme.flow.createNanotasksDialog.snackbar.success'));
                            this.shown = false;
                        } catch (e) {
                            console.error(e.details);
                        }
                    }
                }
            ],
        }
    },
    props: ['client', 'projectName', 'template'],
    computed: {
        nanotaskDefault() {
            let nanotask = {};
            nanotask.id = '';
            nanotask.num_assignable = this.data.settings.num_assignable;
            nanotask.priority = this.data.settings.priority;
            nanotask.props = Object.fromEntries(this.props.map((p) => [p, '']));
            nanotask.reference_answers = Object.fromEntries(this.refs.map((p) => [p, '']));
            return nanotask;
        }
    },
    methods: {
        insertNanotask(nt = this.nanotaskDefault, idx = null) {
            if(idx===null) this.data.nanotasks.push(JSON.parse(JSON.stringify(nt)));
            else this.data.nanotasks.splice(idx, 0, JSON.parse(JSON.stringify(nt)));
        },
        copyNanotask(idx) {
            this.data.nanotasks.splice(idx, 0, JSON.parse(JSON.stringify(this.data.nanotasks[idx])));
        },
        moveNanotask(idx, step) {
            const nt = this.deleteNanotask(idx);
            this.insertNanotask(nt, idx+step);
        },
        deleteNanotask(idx) {
            return this.data.nanotasks.splice(idx, 1)[0];
        }
    },
    watch: {
        props(val, oldVal) {
            const added = val.filter((v) => !oldVal.includes(v));
            const deleted = oldVal.filter((v) => !val.includes(v));
            for(const i in this.data.nanotasks){
                added.forEach((p) => { this.data.nanotasks[i].props[p] = ''; });
                deleted.forEach((p) => { delete this.data.nanotasks[i].props[p] });
            }
        },
        refs(val, oldVal) {
            const added = val.filter((v) => !oldVal.includes(v));
            const deleted = oldVal.filter((v) => !val.includes(v));
            for(const i in this.data.nanotasks){
                added.forEach((p) => { this.data.nanotasks[i].reference_answers[p] = ''; });
                deleted.forEach((p) => { delete this.data.nanotasks[i].reference_answers[p] });
            }
        },
        shown() {
            this.data = {
                settings: {
                    num_assignable: 1,
                    priority: 1,
                    tag: '',
                },
                nanotasks: []
            };
        }
    }
}
</script>
