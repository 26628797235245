var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      dense: "",
      "show-select": "",
      loading: _vm.loading,
      headers: _vm.headers,
      items: _vm.nanotasks,
      "item-key": "id",
      search: _vm.search,
      "footer-props": _vm.footerProps,
      "sort-by": "NanotaskId",
    },
    scopedSlots: _vm._u([
      {
        key: "item.reference_answers",
        fn: function ({ item }) {
          return [
            _c("v-simple-table", {
              attrs: { dense: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c(
                          "tbody",
                          _vm._l(item.reference_answers, function (value, key) {
                            return _c("tr", { key: key }, [
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c("b", [_vm._v(_vm._s(key))]),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "word-break": "break-all" } },
                                [_vm._v(_vm._s(value))]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
      {
        key: "item.props",
        fn: function ({ item }) {
          return [
            _c("v-simple-table", {
              attrs: { dense: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c(
                          "tbody",
                          _vm._l(item.props, function (value, key) {
                            return _c("tr", { key: key }, [
                              _c("td", { staticStyle: { width: "100px" } }, [
                                _c("b", [_vm._v(_vm._s(key))]),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "word-break": "break-all" } },
                                [_vm._v(_vm._s(value))]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
          ]
        },
      },
      {
        key: "item._created_at",
        fn: function ({ item }) {
          return [
            _vm._v(
              " " + _vm._s(_vm.standardDateTimeFormat(item._created_at)) + " "
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedNanotasks,
      callback: function ($$v) {
        _vm.selectedNanotasks = $$v
      },
      expression: "selectedNanotasks",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }