var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "recursive-batch",
      on: {
        mouseover: function ($event) {
          $event.stopPropagation()
          return ((e) => {
            _vm.hovered = true
          }).apply(null, arguments)
        },
        mouseleave: function ($event) {
          $event.stopPropagation()
          return _vm.onMouseLeave.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { id: "node-wrapper" } },
        [
          _vm.depth > 1
            ? _c(
                "div",
                { attrs: { id: "node-actions" } },
                [
                  false
                    ? [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _vm.index !== 0
                                        ? _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "node-action-icon",
                                                  attrs: {
                                                    id: "node-action-icon-arrow-up",
                                                    size: "25",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.swapNodes(
                                                        _vm.index,
                                                        _vm.index - 1
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-arrow-up")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1276008037
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "console.scheme.flow.tooltip.actions.moveUp"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              left: "",
                              bottom: "",
                              "offset-x": "",
                              "open-on-hover": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "node-action-icon",
                                              attrs: {
                                                size: "25",
                                                id: "node-action-icon-dots",
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-dots-vertical")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              324163295
                            ),
                            model: {
                              value: _vm.menuOpen,
                              callback: function ($$v) {
                                _vm.menuOpen = $$v
                              },
                              expression: "menuOpen",
                            },
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "py-0", attrs: { dense: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.addTemplateNode(_vm.index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { small: "", left: "" } },
                                          [_vm._v("mdi-plus")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "console.scheme.flow.node.actions.menu.addTemplate"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.groupNode(_vm.index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { small: "", left: "" } },
                                          [_vm._v("mdi-group")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "console.scheme.flow.node.actions.menu.group"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isBatch
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.ungroupNode(_vm.index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-ungroup")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "console.scheme.flow.node.actions.menu.ungroup"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.index > 0 &&
                                _vm.parentNode.children[_vm.index - 1].children
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveNodeIntoGroup(
                                              "previous",
                                              _vm.index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-arrow-right-top")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "console.scheme.flow.node.actions.menu.moveIntoPreviousGroup"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.isLast &&
                                _vm.parentNode.children[_vm.index + 1].children
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.moveNodeIntoGroup(
                                              "next",
                                              _vm.index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-arrow-right-bottom")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "console.scheme.flow.node.actions.menu.moveIntoNextGroup"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.node.children &&
                                _vm.node.children.length > 1
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.popChildOutOfGroup(
                                              "first"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-arrow-left-top")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "console.scheme.flow.node.actions.menu.moveFirstChildOutOfGroup"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.node.children &&
                                _vm.node.children.length > 1
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.popChildOutOfGroup(
                                              "last"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-arrow-left-bottom")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "console.scheme.flow.node.actions.menu.moveLastChildOutOfGroup"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.parentNode.children.length > 1
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteNode(_vm.index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  left: "",
                                                  color: "error",
                                                },
                                              },
                                              [_vm._v("mdi-delete")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "error--text" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "console.scheme.flow.node.actions.menu.delete"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      !_vm.isLast
                                        ? _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "node-action-icon",
                                                  attrs: {
                                                    size: "25",
                                                    id: "node-action-icon-arrow-down",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.swapNodes(
                                                        _vm.index,
                                                        _vm.index + 1
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-arrow-down")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2306954621
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "console.scheme.flow.tooltip.actions.moveDown"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-card",
            {
              staticClass: "mx-auto",
              style: {
                width: "100%",
                ...(_vm.isBatch ? { border: "1px solid #ccc!important" } : {}),
              },
              attrs: { flat: _vm.isBatch, color: _vm.cardColor },
            },
            [
              _c(
                "div",
                {
                  class: `card-toolbar d-flex lighten-1 ${
                    _vm.isBatch ? "" : "blue-"
                  }grey`,
                },
                [
                  _c(
                    "div",
                    { staticClass: "ma-0 mr-2", attrs: { justify: "center" } },
                    [
                      _vm.isBatch
                        ? _c(
                            "div",
                            { staticClass: "grey node-type-chip" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: "",
                                    color: "white",
                                  },
                                },
                                [_vm._v("mdi-group")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("console.scheme.flow.batch")) +
                                  " "
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "blue-grey node-type-chip" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    small: "",
                                    left: "",
                                    color: "white",
                                  },
                                },
                                [_vm._v("mdi-application-outline")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("console.scheme.flow.template")
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "node-name", style: _vm.input.style },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-1",
                        attrs: { readonly: "", "hide-details": "" },
                        model: {
                          value: _vm.node.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.node, "name", $$v)
                          },
                          expression: "node.name",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "card-toolbar" },
                [
                  _vm.node.statement
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "white--text mr-2",
                          attrs: {
                            small: "",
                            label: "",
                            color: _vm.statements[_vm.node.statement].color,
                          },
                          on: {
                            click: function ($event) {
                              _vm.showCondition = !_vm.showCondition
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { left: "", color: "white" } },
                            [
                              _vm._v(
                                _vm._s(_vm.statements[_vm.node.statement].icon)
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  _vm.statements[_vm.node.statement].i18nKey
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "v-icon",
                            { attrs: { small: "", right: "", color: "white" } },
                            [
                              _vm._v(
                                "mdi-unfold-" +
                                  _vm._s(_vm.showCondition ? "less" : "more") +
                                  "-horizontal"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.node.statement
                    ? _c(
                        "v-chip",
                        {
                          staticClass: "mr-2",
                          attrs: { small: "", label: "" },
                        },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("mdi-pencil-off"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("console.scheme.flow.card.noCondition")
                              ) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-chip",
                    {
                      staticClass: "mr-2",
                      attrs: { small: "", label: "", outlined: "" },
                    },
                    [
                      _vm.node.is_skippable
                        ? _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("mdi-debug-step-over"),
                          ])
                        : _c(
                            "v-icon",
                            { attrs: { left: "", small: "", color: "error" } },
                            [_vm._v("mdi-cancel")]
                          ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `console.scheme.flow.card.${
                                _vm.node.is_skippable ? "" : "not_"
                              }skippable`
                            )
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  !_vm.isBatch
                    ? _c("chip-select", {
                        attrs: {
                          small: "",
                          label: "",
                          outlined: "",
                          items: [
                            {
                              text: _vm.$t(
                                "console.scheme.flow.menu.listNanotasks"
                              ),
                              onClick: () => {
                                _vm.$refs.dlgList.show = true
                              },
                              icon: "mdi-view-list-outline",
                            },
                            {
                              text: _vm.$t(
                                "console.scheme.flow.menu.importNanotasks"
                              ),
                              onClick: () => {
                                _vm.$refs.dlgImport.show = true
                              },
                              icon: "mdi-upload",
                            },
                            {
                              text: _vm.$t(
                                "console.scheme.flow.menu.createNanotasks"
                              ),
                              onClick: () => {
                                _vm.$refs.dlgCreate.shown = true
                              },
                              icon: "mdi-pencil",
                            },
                          ],
                          placeholder: "適用範囲",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "chip",
                              fn: function ({ attrs, on }) {
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            small: "",
                                            label: "",
                                            outlined:
                                              _vm.nanotasks.length === 0,
                                            color:
                                              "indigo" +
                                              (_vm.nanotasks.length > 0
                                                ? " lighten-4"
                                                : ""),
                                            "text-color": "indigo",
                                          },
                                        },
                                        "v-chip",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [
                                          _vm._v(
                                            "mdi-tray" +
                                              _vm._s(
                                                _vm.nanotasks.length > 0
                                                  ? "-full"
                                                  : ""
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "console.scheme.flow.nanotasks"
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(_vm.nanotasks.length) +
                                          ") "
                                      ),
                                      _c(
                                        "v-icon",
                                        { attrs: { right: "", small: "" } },
                                        [_vm._v("mdi-menu-down")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2899701118
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.showCondition
                ? [
                    _vm._l(_vm.node.conditionNew, function (condition, i) {
                      return _c(
                        "div",
                        { key: `${_vm.node.name}-condition-${i}` },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", {
                              staticStyle: {
                                width: "100px",
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center",
                                "text-align": "center",
                              },
                            }),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "pa-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          staticStyle: {
                                            "font-size": "12px",
                                            width: "35%",
                                          },
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            items: [
                                              {
                                                text: "ワーカー",
                                                value: "worker",
                                              },
                                              {
                                                text: "フロー",
                                                value: "session",
                                              },
                                            ],
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [_vm._v("適用範囲")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.scope,
                                            callback: function ($$v) {
                                              _vm.$set(condition, "scope", $$v)
                                            },
                                            expression: "condition.scope",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("v-select", {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            items: [
                                              {
                                                text: "過去訪問回数",
                                                value: "count",
                                              },
                                              {
                                                text: "滞在時間",
                                                value: "time",
                                              },
                                              {
                                                text: "正答率",
                                                value: "accuracy",
                                              },
                                            ],
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [_vm._v("変数")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.variable,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                condition,
                                                "variable",
                                                $$v
                                              )
                                            },
                                            expression: "condition.variable",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            items: [
                                              { text: "<", value: "lt" },
                                              { text: "&le;", value: "le" },
                                              { text: ">", value: "gt" },
                                              { text: "&ge;", value: "ge" },
                                              { text: "=", value: "eq" },
                                              { text: "&ne;", value: "neq" },
                                            ],
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [_vm._v("演算子")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "selection",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.text
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "item",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.text
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.operand,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                condition,
                                                "operand",
                                                $$v
                                              )
                                            },
                                            expression: "condition.operand",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-text-field", {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            "hide-details": "",
                                            type: _vm.valueTypeMap[
                                              condition.variable
                                            ],
                                            suffix:
                                              _vm.valueSuffixMap[
                                                condition.variable
                                              ],
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [_vm._v("値")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: condition.value,
                                            callback: function ($$v) {
                                              _vm.$set(condition, "value", $$v)
                                            },
                                            expression: "condition.value",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    _vm.node.condition
                      ? _c("codemirror", {
                          staticStyle: {
                            "font-size": "12px",
                            height: "100px!important",
                          },
                          attrs: { options: _vm.cmOptions },
                          model: {
                            value: _vm.node.condition,
                            callback: function ($$v) {
                              _vm.$set(_vm.node, "condition", $$v)
                            },
                            expression: "node.condition",
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.isBatch
                ? [
                    _c("v-divider"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "0 10px 0 auto",
                          padding: "25px 0",
                        },
                      },
                      _vm._l(_vm.children, function (child, index) {
                        return _c("recursive-batch", {
                          key: `recursive-batch-${_vm.node}-${index}`,
                          attrs: {
                            client: _vm.client,
                            index: index,
                            "project-name": _vm.projectName,
                            "template-names": _vm.templateNames,
                            "parent-node": _vm.node,
                            node: child,
                            isLast: index == _vm.children.length - 1,
                            depth: _vm.depth + 1,
                          },
                          on: { "parent-update": _vm.relayParentUpdate },
                        })
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      !_vm.isLast ? _c("arrow", { attrs: { depth: _vm.depth } }) : _vm._e(),
      _c("dialog-import", {
        ref: "dlgImport",
        attrs: {
          client: _vm.client,
          projectName: _vm.projectName,
          template: _vm.node.name,
        },
      }),
      _c("dialog-create", {
        ref: "dlgCreate",
        attrs: {
          client: _vm.client,
          projectName: _vm.projectName,
          template: _vm.node.name,
        },
      }),
      _c("dialog-list", {
        ref: "dlgList",
        attrs: {
          client: _vm.client,
          projectName: _vm.projectName,
          template: _vm.node.name,
          nanotasks: _vm.nanotasks,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }