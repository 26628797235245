<template>
    <v-sheet class="text-center mx-auto" color="rgba(0,0,0,0)">
        <v-icon size="70">mdi-arrow-down-thin</v-icon>
    </v-sheet>
</template>

<script>
export default {
    props: ["color", "depth"]
}
</script>
