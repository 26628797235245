var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1400", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "indigo" } },
                [_vm._v("mdi-file-upload")]
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("console.scheme.flow.importNanotasksDialog.title", {
                      templateName: _vm.template,
                    })
                  ) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-file-input", {
                attrs: {
                  "show-size": "",
                  accept: ".json,.JSON",
                  label: _vm.$t(
                    "console.scheme.flow.importNanotasksDialog.fileInputLabel"
                  ),
                },
                on: { change: _vm.getFileContent },
              }),
            ],
            1
          ),
          _c("nanotask-table", {
            attrs: { nanotasks: _vm.contents, search: _vm.search },
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeDialog } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "console.scheme.flow.importNanotasksDialog.buttons.cancel"
                      )
                    )
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.contents.length == 0,
                  },
                  on: { click: _vm.importNanotasks },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "console.scheme.flow.importNanotasksDialog.buttons.confirm"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }