<template>
    <v-data-table
        dense
        sort-desc
        :loading="loading"
        :headers="headers"
        :items="nanotaskGroups"
        :search="search"
        :footer-props="footerProps"
        sort-by="_created_at"
        >
        <template v-slot:item._created_at="{ item }">
            {{ standardDateTimeFormat(item._created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small @click="$emit('delete', item.id, item.name)">mdi-trash-can</v-icon>
        </template>
    </v-data-table>
</template>
<script>
import { standardDateTimeFormat } from '@/lib/utils'

export default {
    data() {
        return {
            loading: false,
            headers: [
                    { text: this.$t('resources.nanotaskGroup.id'),          value: 'id',           width: '10%' },
                    { text: this.$t('resources.nanotaskGroup.name'),        value: 'name',         width: '10%' },
                    { text: this.$t('resources.nanotaskGroup.nanotaskIds'), value: 'nanotask_ids', width: '55%' },
                    { text: this.$t('resources.nanotaskGroup.createdAt'),   value: '_created_at',  width: '15%' },
                    { text: this.$t('resources.nanotaskGroup.actions'),     value: 'actions',      width: '10%' },
                ],
            footerProps: {
                    showFirstLastPage: true,
                    firstIcon: 'mdi-chevron-double-left',
                    lastIcon: 'mdi-chevron-double-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    itemsPerPageOptions: [10,30,50,100,-1]
                },
        }
    },
    props: ['search', 'nanotaskGroups'],
    methods: {
        standardDateTimeFormat,
    },
    watch: {
        nanotaskGroups() {
            this.loading = false;
        }
    }
}
</script>
