var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000", persistent: "" },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "indigo" } },
                [_vm._v("mdi-file-upload")]
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("console.scheme.flow.createNanotasksDialog.title", {
                      templateName: _vm.template,
                    })
                  ) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "text-h6 mt-2" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "console.scheme.flow.createNanotasksDialog.commonSettings.title"
                    )
                  )
                ),
              ]),
              _c("v-divider"),
              _c("v-simple-table", { attrs: { dense: "" } }, [
                _c(
                  "tbody",
                  _vm._l(Object.keys(_vm.data.settings), function (key, i) {
                    return _c("tr", { key: `settings-${i}` }, [
                      _c("th", [_vm._v(_vm._s(_vm.paramNames[key]))]),
                      _c(
                        "td",
                        [
                          ["num_assignable", "priority"].includes(key)
                            ? _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.data.settings[key],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data.settings,
                                      key,
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "data.settings[key]",
                                },
                              })
                            : _c("v-text-field", {
                                attrs: { dense: "", "hide-details": "" },
                                model: {
                                  value: _vm.data.settings[key],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data.settings, key, $$v)
                                  },
                                  expression: "data.settings[key]",
                                },
                              }),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _c("v-divider"),
              _c("p", { staticClass: "text-h6 mt-5" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "console.scheme.flow.createNanotasksDialog.nanotasks.title"
                    )
                  )
                ),
              ]),
              _c("v-combobox", {
                staticStyle: { width: "50%" },
                attrs: {
                  "small-chips": "",
                  dense: "",
                  multiple: "",
                  label: _vm.$t(
                    "console.scheme.flow.createNanotasksDialog.nanotasks.propsCombobox"
                  ),
                },
                model: {
                  value: _vm.props,
                  callback: function ($$v) {
                    _vm.props = $$v
                  },
                  expression: "props",
                },
              }),
              _c("v-combobox", {
                staticStyle: { width: "50%" },
                attrs: {
                  "small-chips": "",
                  dense: "",
                  multiple: "",
                  label: _vm.$t(
                    "console.scheme.flow.createNanotasksDialog.nanotasks.refAnsCombobox"
                  ),
                },
                model: {
                  value: _vm.refs,
                  callback: function ($$v) {
                    _vm.refs = $$v
                  },
                  expression: "refs",
                },
              }),
              _c("v-divider"),
              _c(
                "v-simple-table",
                { attrs: { dense: "" } },
                _vm._l(_vm.data.nanotasks, function (nt, i) {
                  return _c("tbody", { key: `nanotask-${i}` }, [
                    _c("tr", [
                      _c(
                        "th",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "console.scheme.flow.createNanotasksDialog.nanotasks.name"
                                )
                              ) +
                              " " +
                              _vm._s(i + 1) +
                              " "
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copyNanotask(i)
                                },
                              },
                            },
                            [_vm._v("mdi-content-copy")]
                          ),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.deleteNanotask(i)
                                },
                              },
                            },
                            [_vm._v("mdi-trash-can-outline")]
                          ),
                          i != 0
                            ? _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveNanotask(i, -1)
                                    },
                                  },
                                },
                                [_vm._v("mdi-arrow-up")]
                              )
                            : _vm._e(),
                          i != _vm.data.nanotasks.length - 1
                            ? _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.moveNanotask(i, 1)
                                    },
                                  },
                                },
                                [_vm._v("mdi-arrow-down")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("v-simple-table", { attrs: { dense: "" } }, [
                            _c(
                              "tbody",
                              _vm._l(Object.keys(nt), function (key) {
                                return _c(
                                  "tr",
                                  { key: `nanotask-${i}-${key}` },
                                  [
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.paramNames[key])),
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        key == "id"
                                          ? _c("v-text-field", {
                                              attrs: {
                                                dense: "",
                                                "hide-details": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.data.nanotasks[i][key],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data.nanotasks[i],
                                                    key,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "data.nanotasks[i][key]",
                                              },
                                            })
                                          : _vm._e(),
                                        ["num_assignable", "priority"].includes(
                                          key
                                        )
                                          ? _c("v-text-field", {
                                              attrs: {
                                                dense: "",
                                                "hide-details": "",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.data.nanotasks[i][key],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.data.nanotasks[i],
                                                    key,
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "data.nanotasks[i][key]",
                                              },
                                            })
                                          : _vm._e(),
                                        ["props", "reference_answers"].includes(
                                          key
                                        )
                                          ? _c(
                                              "v-simple-table",
                                              { attrs: { dense: "" } },
                                              [
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    Object.keys(nt[key]),
                                                    function (key_) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key: `nanotask-${i}-${key_}`,
                                                        },
                                                        [
                                                          _c("th", [
                                                            _vm._v(
                                                              _vm._s(key_)
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    dense: "",
                                                                    "hide-details":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.data
                                                                        .nanotasks[
                                                                        i
                                                                      ][key][
                                                                        key_
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .data
                                                                            .nanotasks[
                                                                            i
                                                                          ][
                                                                            key
                                                                          ],
                                                                          key_,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "data.nanotasks[i][key][key_]",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              ),
              _c("v-divider"),
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.insertNanotask()
                    },
                  },
                },
                [_vm._v("mdi-plus")]
              ),
              _c("v-divider"),
            ],
            1
          ),
          _c("tutti-dialog-actions", { attrs: { actions: _vm.actions } }),
          _c("tutti-snackbar", { ref: "snackbar" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }